import React, {useEffect, useState} from 'react';
import SearchField from '../../common/SearchField';
import useDebounce from '../../common/UseDebounce';
import Memo from '../../common/data/Memo';
import labels from '../../../constants/configuration/memorizedTexts/MemorizedTextSearch.json';

type Props = {
  searchTermChanged: () => void
};

const MemorizedTextSearch = ({ searchTermChanged }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    const searchTermHasChanged = async () => {
      if (!searchTerm) {
        searchTermChanged({ searchTerm, filteredMemoKeys: [] });
        return;
      }
      const memos = await Memo.findAll();
      const filteredMemoKeysWithDuplicates = memos
        .map(({ k, val }) => val.nodes.map(node => ({ k, ...node })))
        .flat()
        .map(({ k, texts }) => texts.map(text => ({ k, ...text })))
        .flat()
        .filter(({ text }) => text.includes(debouncedSearchTerm))
        .map(({ k }) => k);
      const filteredMemoKeys = [...new Set(filteredMemoKeysWithDuplicates)];
      searchTermChanged({ searchTerm, filteredMemoKeys });
    };

    searchTermHasChanged().then();
  }, [debouncedSearchTerm, searchTerm, searchTermChanged]);

  return (
    <SearchField
      q={searchTerm}
      searchChange={async e => {
        await setSearchTerm(e.target.value);
      }}
      itemMdValue={12}
      placeholder={labels.searchFieldPlaceholder}
    />
  );
};

export default MemorizedTextSearch;
