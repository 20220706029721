import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Delete, SaveAlt, SupervisorAccount} from '@material-ui/icons';

import labels from '../../../constants/labels/patient/report/ReportGeneration.json';
import MoreActionsMenu from '../../home/MoreActionsMenu';

import ListStyle from '../../../constants/styles/ListStyle.json';
import {useWarnings, Warning} from '../../common/Warning';
import log from '../../Logger';
import DocumentModels from '../../common/data/DocumentModels';
import server from '../../../constants/server';
import User from "../../common/data/User";

const useStyles = makeStyles({
  fullWidth: {
    width: '100%'
  },
  ...ListStyle
});

const DocumentCheckupModels = () => {
  const classes = useStyles();
  const [warnings] = useWarnings();
  const [documentCheckupModelList, setDocumentCheckupModelList] = useState([]);

  const updateContent = useCallback(async () => {
    try {
      const userValues = await User.getUser(["id"]);
      const filenames = await DocumentModels.listFilesOnDocumentModelStorage();
      const mapped = filenames.map(customCheckupModel => ({...customCheckupModel, isFromAnotherUser: customCheckupModel.userId !== userValues.id}));
      setDocumentCheckupModelList(mapped);
    } catch (err) {
      log.error('Error retrieving datas', err);
    }
  }, []);

  useEffect(() => {
    updateContent().then();
  }, [updateContent]);

  const deleteCustomCheckupModel = element => async () => {
    await DocumentModels.deleteFileOnDocumentModelStorage(element);
    await updateContent();
  };

  const addTiersModel = async (event) => {
    const filePath = event.target.files[0]
    if (!filePath) {
      return;
    }
    await DocumentModels.addFileOnDocumentModelStorage(filePath);
    await updateContent();
  };

  // const saveOriginalModel = modelType => async () => {
  //   await clearWarnings();
  //   try {
  //     const content = await getTemplateFileContent(modelType==='odt' ? templateOdt : templateRtf);
  //     download(`MonNouveauTemplate.${modelType}`, content);
  //   } catch (e) {
  //     await addWarning();
  //   }
  // };

  return (
    <Grid item xs={12} className={classes.fullWidth}>
      <Typography variant="h6">{labels.checkupModels.title}</Typography>
      <Grid item container>
        <Grid item xs={12}>
          <Typography variant="body1">{labels.checkupModels.subTitle}</Typography>
        </Grid>
        <Warning warnings={warnings} />
        <Grid item xs={12} container>
          <Grid item xs={2}>
            <MoreActionsMenu
              format="button"
              buttonProps={{ variant: 'contained', color: 'primary' }}
              menuItemEntries={[
                {
                  key: 'entry0',
                  element: <a href={`/templates/orthoTemplate_odt.odt`}
                              download={'MonNouveauTemplate.odt'}
                              target="_blank"
                              rel="noreferrer">{labels.checkupModels.startFromOriginalModel.fromOdtModel}</a>,
                  onClick: () => {}
                },
                {
                  key: 'entry1',
                  element: <a href={`/templates/orthoTemplate_rtf.rtf`}
                              download={'MonNouveauTemplate.rtf'}
                              target="_blank"
                              rel="noreferrer">{labels.checkupModels.startFromOriginalModel.fromRtfModel}</a>,
                  onClick: () => {}
                }
              ]}
              icon={<SaveAlt />}
              buttonText={labels.checkupModels.startFromOriginalModel.label}
            />
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={2}>
            <input
              id="contained-button-file"
              type="file"
              hidden
              onChange={addTiersModel}
              accept=".odt,.rtf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
            <Tooltip title={labels.checkupModels.addTiersModelHint}>
              <label htmlFor="contained-button-file">
                <Button variant="outlined"  component="span">
                  {labels.checkupModels.addTiersModel}
                </Button>
              </label>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <List style={{ width: '100%' }}>
            {documentCheckupModelList.map(customCheckupModel => (
              <Box boxShadow={2} className={classes.listItemBox} key={customCheckupModel.fullName}>
                <ListItem
                  className={classes.orthoscribeList}
                  button
                  component={Button}
                  href={`${server.documentStorage.documentModel}/download/${customCheckupModel.escapedFullName}`}
                >
                  {customCheckupModel.isFromAnotherUser &&
                    <ListItemIcon>
                      <SupervisorAccount />
                    </ListItemIcon>
                  }
                  <ListItemText primary={`${customCheckupModel.isFromAnotherUser ? customCheckupModel.fullName : customCheckupModel.label}`} />
                  {! customCheckupModel.isFromAnotherUser &&
                    <ListItemSecondaryAction>
                      <Tooltip title={labels.checkupModels.erase}>
                        <IconButton onClick={deleteCustomCheckupModel(customCheckupModel.escapedFullName)}>
                          <Delete fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  }
                </ListItem>
              </Box>
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentCheckupModels;
