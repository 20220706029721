import React, {Fragment, useEffect, useState} from 'react';
import _ from "lodash";
import {Save} from "@material-ui/icons";

import defaultReportGenerationOptions from "../../../constants/user/defaultReportGenerationOptions";
import User from "../../common/data/User";
import SaveCancelRow from "../../common/SaveCancelRow";
import routes from "../../../constants/routes";
import ReportArrayForm from "../../common/reportOptions/ReportArrayForm";
import exampleContent from "../../../constants/patient/exampleContent.json";

// eslint-disable-next-line no-unused-expressions
const TableOptions = () => {
  const [reloadChart, setReloadChart] = useState(true);
  const [content, setContent] = useState({
    ...exampleContent,
    options: defaultReportGenerationOptions
  });

  useEffect(() => {
    const loadOptions = async () => {
      const { val: loadedOptions } = await User.getReportGenerationOptions();
      setContent({ ...content, options: loadedOptions });
    };

    loadOptions().then();
  }, []);

  const change = async event => {
    const newOptions = _.set(content.options, event.target.name, event.target.value);
    setReloadChart(!reloadChart);
    setContent({ ...content, options: newOptions });
  };

  const switchChange = name => async event => {
    await change({ target: { name, value: event.target.checked } });
  };

  const handleSubmit = async () => {
    await User.saveReportGenerationOptions(content.options);
  };

  return (
    <Fragment>
      <SaveCancelRow
        cancelRoute={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.MAIN)}
        saveAction={handleSubmit}
        saveIcon={<Save />}
        saveTooltip="Enregistrer"
      />
      <ReportArrayForm
        options={content.options}
        content={content}
        reloadChart={reloadChart}
        renewal
        switchChange={switchChange}
        change={change}
      />
    </Fragment>
  );
};

export default TableOptions;
