export const windows = 'windows';
export const android = 'android';
export const mac = 'mac';
export const iOS = 'ios';
export const linux = 'linux';
export const unknownOS = 'unknownOS';

const detectOS = () => {
    // if a browser has no support for navigator.userAgentData.platform use platform as fallback
    const userAgent = (navigator?.userAgentData?.platform ?? navigator.platform).toLowerCase();

    if (userAgent.includes('win')) {
        return windows;
    } else if (userAgent.includes('android')) {
        return android;
    } else if (userAgent.includes('mac')) {
        return mac;
    } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
        return iOS;
    } else if (userAgent.includes('linux')) {
        return linux;
    }
    return unknownOS;
}

export default detectOS;