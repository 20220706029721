import React, {Fragment, useEffect, useState} from 'react';
import _ from 'lodash';
import {Save} from '@material-ui/icons';
import ReportOptionsForm from '../../common/reportOptions/ReportOptionsForm';
import routes from '../../../constants/routes';
import SaveCancelRow from '../../common/SaveCancelRow';
import User from '../../common/data/User';
import defaultReportGenerationOptions from "../../../constants/user/defaultReportGenerationOptions";

const ReportOptions = () => {
  const [reloadChart, setReloadChart] = useState(true);
  const [content, setContent] = useState({
    bilan: {},
    patient: {},
    options: defaultReportGenerationOptions
  });

  useEffect(() => {
    const loadOptions = async () => {
      const { val: loadedOptions } = await User.getReportGenerationOptions();
      setContent({ ...content, options: loadedOptions });
    };

    loadOptions().then();
  }, []);

  const change = async event => {
    const newOptions = _.set(content.options, event.target.name, event.target.value);
    setReloadChart(!reloadChart);
    setContent({ ...content, options: newOptions });
  };

  const switchChange = name => async event => {
    await change({ target: { name, value: event.target.checked } });
  };

  const handleSubmit = async () => {
    await User.saveReportGenerationOptions(content.options);
  };

  const resetHorizBar = () => {
    const defaultValues = {
      horizBarSize: 10,
      displayBars: true,
      tableChartFormat: 'simple',
      tableChartOptions: { renewalMark: 'diamond' }
    };
    const newOptions = _.merge(content.options, defaultValues);
    setContent({ ...content, options: newOptions });
    setReloadChart(!reloadChart);
  };

  return (
    <Fragment>
      <SaveCancelRow
        cancelRoute={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.MAIN)}
        saveAction={handleSubmit}
        saveIcon={<Save />}
        saveTooltip="Enregistrer"
      />
      <ReportOptionsForm
        options={content.options}
        reloadChart={reloadChart}
        content={content}
        change={change}
        switchChange={switchChange}
        resetHorizBar={resetHorizBar}
      />
    </Fragment>
  );
};

export default ReportOptions;
