import {parseThresholdAsZscore} from './PrepareTestForDisplay';

const NORMALIZED_DIFF_ZSCORE = -1.5;
const NORMALIZED_PATHO_ZSCORE = -2.0;

export const getThresholds = (testConfig, { pathoThresholdAsZscore, difficultyThresholdAsZscore, pathoThreshold, difficultyThreshold }) => {
  if (testConfig && (testConfig.patho || testConfig.difficulty)) {
    const {threshold: pathoConfig, unit} = parseThresholdAsZscore(testConfig.patho, -1.6449);
    const {threshold: difficultyConfig} = parseThresholdAsZscore(testConfig.difficulty, -1.3);
    return { pathoThresholdAsZscore: Number(pathoConfig), difficultyThresholdAsZscore: Number(difficultyConfig), unit };
  }
  if (pathoThreshold && difficultyThreshold) {
    const pathoUnit = pathoThreshold.split(":")[0];
    const difficultyUnit = difficultyThreshold.split(":")[0];
    const unit = pathoUnit === difficultyUnit ? pathoUnit : 'ET';
    return { pathoThresholdAsZscore: Number(pathoThresholdAsZscore), difficultyThresholdAsZscore: Number(difficultyThresholdAsZscore), unit};
  }
  return { pathoThresholdAsZscore: Number(pathoThresholdAsZscore), difficultyThresholdAsZscore: Number(difficultyThresholdAsZscore), unit: 'ET'};
}

export const getNormalizedZScore: (number, number, number) => number = (zscore, pathoThresholdAsZscore, difficultyThresholdAsZscore) => {
  const zscoreConvert = Number(zscore);

  if (zscoreConvert > Math.abs(pathoThresholdAsZscore)) {
    return (zscoreConvert / Math.abs(pathoThresholdAsZscore)) * Math.abs(NORMALIZED_PATHO_ZSCORE);
  }
  if (zscoreConvert > Math.abs(difficultyThresholdAsZscore)) {
    // above abs(difficultyThresholdAsZscore)
    return (
      Math.abs(NORMALIZED_DIFF_ZSCORE) +
      ((Math.abs(zscoreConvert) - Math.abs(difficultyThresholdAsZscore)) /
        Math.abs(pathoThresholdAsZscore - difficultyThresholdAsZscore)) *
        Math.abs(NORMALIZED_PATHO_ZSCORE - NORMALIZED_DIFF_ZSCORE)
    );
  }
  if (zscoreConvert > 0) {
    // between 0 and abs(difficultyThresholdAsZscore)
    return (zscoreConvert / difficultyThresholdAsZscore) * NORMALIZED_DIFF_ZSCORE;
  }
  if (zscoreConvert > difficultyThresholdAsZscore) {
    // between difficultyThresholdAsZscore and 0
    return (zscoreConvert / difficultyThresholdAsZscore) * NORMALIZED_DIFF_ZSCORE;
  }
  if (zscoreConvert > pathoThresholdAsZscore) {
    // between pathoThresholdAsZscore and difficultyThresholdAsZscore
    return (
      NORMALIZED_DIFF_ZSCORE -
      (Math.abs(zscoreConvert - difficultyThresholdAsZscore) /
        Math.abs(pathoThresholdAsZscore - difficultyThresholdAsZscore)) *
        Math.abs(NORMALIZED_PATHO_ZSCORE - NORMALIZED_DIFF_ZSCORE)
    );
  }
  return (zscoreConvert / pathoThresholdAsZscore) * NORMALIZED_PATHO_ZSCORE;
};
