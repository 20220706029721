import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import labels from "../../constants/labels/user/Support.json";
import detectOs from "../../utils/detectOs";

const Support = () => {

    const links = {
        windows: "https://download.teamviewer.com/download/TeamViewerQS_fr.exe",
        android: "https://www.teamviewer.com/fr/download/android/",
        mac: "https://download.teamviewer.com/download/TeamViewerQS.dmg",
        ios: "https://download.teamviewer.com/download/TeamViewerQS.dmg",
        linux: "https://www.teamviewer.com/fr/download/linux/",
        unknownOS: "https://www.teamviewer.com/fr/download/"
    }
    const teamViewerLink = links[detectOs()];

    return (
        <Grid item container md={12} spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" style={{paddingBottom: '1em', paddingTop: '1em'}}>
                    {labels.header.support}
                </Typography>
                <Typography variant="p">
                    {labels.description}
                </Typography>
                <nbsp/>
                <a href={teamViewerLink} target="_blank" rel="noreferrer noopener">{labels.supportSoftwareName}</a>
            </Grid>
        </Grid>
    );
};

export default Support;
