import User from './User';
import {fetchJson} from '../OrthoscribeWebSite';
import server from '../../../constants/server';

export default class DocumentModels {

  static async listFilesOnDocumentModelStorage() {
    const { val: jwToken } = await User.retrieveValueFromDb({
      keyValue: 'jwt'
    });
    const init = {
      headers: new Headers({
        Authorization: `Bearer ${jwToken}`
      })
    };
    const list = await fetchJson(server.documentStorage.documentModel, init);
    return list.map(({ objectKey }) => objectKey).map(s => {
      const split = s.split("/");
      return ({label: split[1], fullName: s, userId: split[0],escapedFullName: `${split[0] + '/' + encodeURIComponent(split[1])}`});
    });
  }

  static async deleteFileOnDocumentModelStorage(element) {
    const { val: jwToken } = await User.retrieveValueFromDb({
      keyValue: 'jwt'
    });
    const init = {
      method: 'DELETE',
      headers: new Headers({
        Authorization: `Bearer ${jwToken}`
      })
    };
    await fetch(`${server.documentStorage.documentModel}/${element}`, init);
  }

  static async addFileOnDocumentModelStorage(filePath) {
    const data = new FormData();
    data.append('file', filePath);
    data.append('filename', filePath.name);
    data.append('mimetype', filePath.type | "application/octet-stream");

    const { val: jwToken } = await User.retrieveValueFromDb({
      keyValue: 'jwt'
    });

    const init = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${jwToken}`
      }),
      body: data
    }

    await fetch(`${server.documentStorage.documentModel}/upload`, init);
  }

}
